import React, { useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import Head from 'next/head'
import {
  Button,
  Container,
  Header,
  Icon,
  Menu,
  Image,
  Segment,
  Sidebar,
  Visibility,
  Grid,
  List,
} from 'semantic-ui-react'

import RequestCall from 'components/RequestCall'

const isInverted = false

const Footer = () => (
  <Segment inverted vertical className={'footer'}>
    <Container>
      <Grid divided inverted stackable>
        <Grid.Row>
          <Grid.Column width={3}>
            <Header inverted as="h4" content="О нас" />
            <List link inverted>
              <Link href="/contacts" passHref={true}>
                <List.Item as="a">Контакты</List.Item>
              </Link>
              <Link href="/factory" passHref={true}>
                <List.Item as="a">Производство</List.Item>
              </Link>
              <Link href="/policy" passHref={true}>
                <List.Item as="a">Политика конфиденциальности</List.Item>
              </Link>
              <Link href="/sitemap.xml" passHref={true}>
                <List.Item as="a">Sitemap</List.Item>
              </Link>
            </List>
          </Grid.Column>
          <Grid.Column width={3}>
            <Header inverted as="h4" content="Услуги" />
            <List link inverted>
              <Link href="/#projects" passHref={true}>
                <List.Item as="a">Готовые проекты</List.Item>
              </Link>
              <List.Item as="a">Конфигуратор</List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={7}>
            <Header as="h4" inverted>
              ООО "Аврора Хоумс" - 2018-2021
            </Header>
            <p>
              <a href="tel:+79877096654">+7 987 709 66 54</a> <br />
              <a href="tel:+79613333737">+7 961 333 37 37</a>
            </p>
            <p>612260, Кировская обл, г. Яранск, ул. Карла Маркса, дом № 183</p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  </Segment>
)

const ResponsiveContainer = ({ children, heading }) => {
  const router = useRouter()

  const [fixed, setFixedMenu] = useState(false)
  const [sidebarOpened, setSidebarOpened] = useState(false)

  return (
    <Sidebar.Pushable>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <Sidebar
        as={Menu}
        animation="overlay"
        inverted={isInverted}
        onHide={() => setSidebarOpened(false)}
        vertical
        visible={sidebarOpened}
      >
        <Link href="/" passHref={true}>
          <Menu.Item as="a" active={router.pathname == '/'}>
            Главная
          </Menu.Item>
        </Link>
        <Link href="/#projects" passHref={true}>
          <Menu.Item as="a">Проекты</Menu.Item>
        </Link>
        <Menu.Item as="a" href="/blog">
          Блог
        </Menu.Item>
        <Link href="/factory" passHref={true}>
          <Menu.Item as="a" active={router.pathname == '/factory'}>
            Производство
          </Menu.Item>
        </Link>
        <Link href="/contacts" passHref={true}>
          <Menu.Item as="a" active={router.pathname == '/contacts'}>
            Контакты
          </Menu.Item>
        </Link>
      </Sidebar>

      <Sidebar.Pusher dimmed={sidebarOpened}>
        <Visibility
          once={false}
          onBottomPassed={() => setFixedMenu(true)}
          onBottomPassedReverse={() => setFixedMenu(false)}
        >
          <Segment textAlign="center" inverted={isInverted} style={{ padding: '1em 0em' }} vertical>
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={isInverted && !fixed}
              pointing={!fixed}
              secondary={!fixed}
              size="large"
              style={{ borderBottom: 'none' }}
            >
              <Container>
                <div className="desktop-menu">
                  <Link href={'/'}>
                    <a>
                      <Image
                        src={'/logo2.png'}
                        width={50}
                        height={43}
                        className={'spaced'}
                        style={{ bottom: '-4px', marginRight: '1em' }}
                        key="logo"
                      />
                    </a>
                  </Link>
                  <Link href="/#projects" passHref={true}>
                    <Menu.Item as="a" active={router.pathname == '/'}>
                      Проекты
                    </Menu.Item>
                  </Link>
                  <Menu.Item as="a" href="/blog">
                    Блог
                  </Menu.Item>
                  <Link href="/factory" passHref={true}>
                    <Menu.Item as="a" active={router.pathname == '/factory'}>
                      Производство
                    </Menu.Item>
                  </Link>
                  <Link href="/contacts" passHref={true}>
                    <Menu.Item as="a" active={router.pathname == '/contacts'}>
                      Контакты
                    </Menu.Item>
                  </Link>
                </div>
                <div className="mobile-menu">
                  <Menu.Item onClick={() => setSidebarOpened(true)}>
                    <Icon name="sidebar" />
                  </Menu.Item>
                </div>

                <Menu.Item position="right">
                  <RequestCall
                    trigger={<Button inverted={isInverted && !fixed}>Обратный звонок</Button>}
                  />
                </Menu.Item>
              </Container>
            </Menu>
            {heading}
          </Segment>
        </Visibility>

        {children}

        <Footer />
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  )
}

export default ResponsiveContainer
