import React from 'react'
import { Modal, Button, Header, Form, Image, Input, Message } from 'semantic-ui-react'
import ReCAPTCHA from 'react-google-recaptcha'
import { reachGoal as YandexReachGoal } from 'components/YandexMetrika'

const RequestCall = ({ trigger }) => {
  const [open, setOpen] = React.useState(false)
  const [formState, setFormState] = React.useState({
    name: '',
    phone: '',
    sent: false,
    progress: false,
    error: null,
    errors: { name: null, phone: null },
  })
  const recaptcha = React.useRef(null)

  const handleName = (e, { value }) => setFormState((item) => ({ ...item, name: value }))
  const handlePhone = (e, { value }) => setFormState((item) => ({ ...item, phone: value }))

  const onSubmit = () => {
    const errors = { name: null, phone: null }
    const phoneValidate = /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/gm
    if (!formState.name) {
      errors.name = 'Пожалуйста представьтесь'
    } else if (!formState.phone) {
      errors.phone = 'Пожалуйста укажите номер телефона'
    } else if (!formState.phone.match(phoneValidate)) {
      errors.phone = 'Пожалуйста укажите корректный номер телефона'
    }
    setFormState((item) => ({ ...item, errors: errors }))
    if (!!errors.phone || !!errors.name) {
      return false
    }
    setFormState((item) => ({ ...item, progress: true }))
    recaptcha.current.execute().then((response) => {
      fetch('/api/send-call-request', {
        body: JSON.stringify({
          name: formState.name,
          phone: formState.phone,
          key: response,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
        .then((response) => {
          return response.json()
        })
        .then((response) => {
          if (response.success) {
            setFormState((item) => ({ ...item, progress: false, sent: true }))
            setTimeout(() => {
              setOpen(false)
            }, 3000)
            YandexReachGoal('request-sent', { type: 'call' })
          } else {
            setFormState((item) => ({ ...item, progress: false, error: response.message }))
          }
        })
        .catch((response) => {
          setFormState((item) => ({ ...item, progress: false, error: true }))
        })
    })
  }

  const onOpen = () => {
    setFormState({
      name: '',
      phone: '',
      sent: false,
      progress: false,
      error: null,
      errors: { name: null, phone: null },
    })
    setOpen(true)
    YandexReachGoal('request-form', { type: 'call' })
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={onOpen}
      open={open}
      trigger={trigger}
      size={'tiny'}
    >
      <Modal.Header>Мы перезвоним</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <div style={{ fontSize: '1.2rem', paddingBottom: '14px' }}>
            Оставьте номер телефона и в ближайшее время наш менеджер с вами свяжется
          </div>
          <Form onSubmit={onSubmit} success={formState.sent} error={!!formState.error}>
            {!formState.sent && (
              <Form.Field
                id="form-input-control-first-name"
                control={Input}
                label="Имя"
                placeholder="Имя"
                error={formState.errors.name}
                onChange={handleName}
              />
            )}
            {!formState.sent && (
              <Form.Field
                id="form-input-control-last-name"
                control={Input}
                label="Номер телефона"
                placeholder="+7(900)123-4567"
                error={formState.errors.phone}
                onChange={handlePhone}
              />
            )}
            <Message
              success
              header="Запрос отправлен"
              content="Мы свяжемся с вами в ближайшее время"
            />
            {!!formState.error && (
              <Message error header="Запрос не был отправлен" content={formState.error} />
            )}
          </Form>
          <ReCAPTCHA
            ref={recaptcha}
            size="invisible"
            sitekey={process.env.NEXT_PUBLIC_GOOGLE_CAPTCHA_SITEKEY}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          type={'submit'}
          content="Жду звонка"
          labelPosition="right"
          icon="checkmark"
          onClick={onSubmit}
          positive
          disabled={formState.sent || formState.progress}
          loading={formState.progress}
        />
      </Modal.Actions>
    </Modal>
  )
}

export default RequestCall
